<template>
    <div>
        <component
            :is="$mapaTelas(tela.tipo)"
            v-if="!remontar"
            :tela="tela"
            :dados="dados"
            @voltar="voltar"
            @processar="processar"
        />
    </div>
</template>

<script>
export default {
    name: "tela",
    props: {
        tela: {
            type: Object,
            required: true,
        },
        dados: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            remontar: false,
        };
    },
    methods: {
        forcarRemontagem() {
            this.remontar = true;
            this.$nextTick(() => {
                this.remontar = false;
            });
        },
        processar(dados) {
            this.$servicoAtendimento.processarTela(dados);
            this.forcarRemontagem();
        },
        voltar() {
            this.$servicoAtendimento.voltar();
            this.forcarRemontagem();
        },
    },
};
</script>
