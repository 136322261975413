<template>
    <div>
        <div class="ufv-btn-voltar">
            <v-row>
                <ViewBotaoVoltar @voltar="voltar" />
            </v-row>
        </div>
        <v-card>
            <div class="ufv-container-text">
                <div class="text-h4">{{ tela.pergunta }}</div>
            </div>
            <v-container class="ufv-container-opcoes">
                <div
                    v-for="(opcao, i) in tela.opcoes"
                    v-bind:item="opcao"
                    v-bind:key="i"
                    class="ufv-container-opcoes-opcao"
                    @click="selecionaOpcao(opcao)"
                >
                    <v-hover v-slot="{ hover }">
                        <v-banner
                            :elevation="hover ? 12 : 1"
                            rounded
                            class="ufv-pointer"
                        >
                            <v-row>
                                <v-col cols="auto">
                                    <v-avatar size="36" color="#eee">
                                        {{ i + 1 }}
                                    </v-avatar>
                                </v-col>
                                <v-col class="ufv-container-opcao-texto">
                                    {{ opcao.nome }}
                                </v-col>
                            </v-row>
                        </v-banner>
                    </v-hover>
                </div>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import ViewBotaoVoltar from "@/views/atendimento/arvore/elementos/ViewBotaoVoltar";

export default {
    name: "telaOpcoes",
    components: {
        ViewBotaoVoltar: ViewBotaoVoltar,
    },
    emits: ["processar", "voltar"],
    props: {
        tela: {
            type: Object,
            required: true,
        },
    },
    methods: {
        selecionaOpcao(opcao) {
            this.$emit("processar", opcao);
        },
        voltar() {
            this.$emit("voltar");
        },
    },
};
</script>

<style scoped>
.ufv-container-opcoes {
    margin: 5px;
}
.ufv-container-opcoes-opcao {
    padding: 5px;
}
.ufv-container-opcao-texto {
    align-self: center;
    word-break: keep-all;
}
.ufv-pointer {
    cursor: pointer;
}
</style>
